import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Expandeco extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Expandeco'}
          description={'Prípadová štúdia'}
          text={'Pre klienta sme nakódovali modernú microsite.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'kódovanie front-endu (bootstrap)' },
          ]} />

        <AboutProject
          text={'Spolupráca s Expandeco prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Expandeco zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/microsite/expandeco'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'Ceny',
              image: data.pageTwo,
            },
            {
              name: 'Ako to funguje',
              image: data.pageThree,
            },
            {
              name: 'Kalkulačka',
              image: data.pageFour,
            },
          ]} />
        
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>

        <div className={'dark-section overflow'}>
          <Reveal>
            <div className={'container'}>
              <div className={'row wrapper'}>
                <div className={'col-12 col-xl-5'}>
                  <div className={'text-wrapper'}>
                    <h2 className={'cs-title'}>Mobilná verzia</h2>
                    <p className="description">Väčšina návštevníkov prichádza cez mobilné zariadenia. Nazabúdajte na to.</p>
                    <p className="description-smaller">Responzívna verzia projektu je pre nás viac ako dôležitá. Stránky preto navrhujeme od samého začiatku tak, aby boli prehľadné a použiteľné aj na mobiloch, tabletoch či iných rozlíšeniach. Len vďaka tomu zabezpečíte svojim návštevníkom plnohodnotné <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> a zvýšite svoju šancu, aby využili Vaše služby, alebo zakúpili Váš produkt, či vykonali inú požadovanú akciu. Mobilné verzie dôkladne testujeme a simulujeme rôzne podmienky a zariadenia, aby sme zaručili čo najlepší zážitok pri prezeraní Vášho projektu.</p>
                  </div>
                  <Img className={'img-overflow'} alt={this.constructor.name} fluid={data.screenTwo.childImageSharp.fluid} />
                </div>
                <div className={'col-12 col-xl-7 hand-wrapper'}>
                  <Img alt={this.constructor.name} fluid={data.screenOne.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </Reveal>
        </div>

        {/*        <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.expandeco.com" target='_blank' className='link-secondary'>www.expandeco.com</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Matchinx'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/microsite/matchinx'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/expandeco/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Expandeco_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/expandeco.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/expandeco1.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/expandeco2.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/expandeco5.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenOne: file(relativePath: { eq: "case-studies/Expandeco_screen_1.png" }) {
        childImageSharp {
            fluid( maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTwo: file(relativePath: { eq: "case-studies/Expandeco_screen_2.png" }) {
        childImageSharp {
            fluid( maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Expandeco
